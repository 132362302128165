<template>
    <main id="liste" v-if="contact">
        <HeaderTab :title="contact.contact_lastname + ' ' + contact.contact_firstname" :swiper_tabs="swiper_tabs"  :back_action="backToList"/>

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <router-view :contact="$cache.contact"></router-view>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <main v-else>
        <LoadingSpinner class="col-12" />
    </main>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Contact from "@/mixins/Contact.js"

    export default {
        name: "contactFiche",
        mixins: [Navigation, Contact],
        data () {
            return {
                param_tab: '',
                actual_tab: 'contactCoordonnees',
                default_tab: 'contactCoordonnees',
                contact: null,
                // events_tab: {
                //     "SaisonFiche::reload_season": (season_label) => {
                //         this.season.season_label = season_label
                //     },
                // },
                tabsToReload: []
            }
        },

        mounted() {
            this.param_tab = this.$route.params.actual_tab
            this.contact_id = this.$route.params.contact_id
            this.init_component()
        },
        beforeDestroy() {
            this.$cache.contact = null
        },
        methods: {
            async init_component() {
                this.loadContact()
            },
            async loadContact() {
                this.contact = await this.getContactById(this.contact_id)
                this.$cache.contact = this.contact
            },

            backToList() {
                this.$router.push({name: "contactList"})
            }
        },
        
        computed:{
            swiper_tabs() {
                return [{
                        id: 'coordonnees',
                        label: 'tiers.menu_coordonnees',
                        active: false,
                        href: 'contactCoordonnees'
                    },
                    {
                        id: 'tiers',
                        label: 'tiers.tiers',
                        active: false,
                        href: 'contactTiers'
                    },
                    {
                        id: 'chevaux',
                        label: 'tiers.menu_chevaux',
                        active: false,
                        href : 'contactHorse'
                    }
                ]
            }
        },
        watch:{
            '$route' (to, from) {
                const index = this.tabsToReload.indexOf(to.name)
                if(index > -1)
                {
                    this.$emit(to.name + "::reloadTabs")
                    this.tabsToReload.splice(index, 1)
                }
            }
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
        }
    }
</script>
